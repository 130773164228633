
































































































































import { Component, Vue } from "vue-property-decorator";
@Component
export default class ApplyAccess extends Vue {
	private applyForm = {
		name: "",
		region: ""
	};
	private imageUrl = "";
	private fileList = [];
	private dialogImageUrl = "";
	private dialogVisible = false;

	private handleRemove(file: string, fileList: object) {
		console.log(file, fileList);
	}

	private handlePictureCardPreview(file: any) {
		this.dialogImageUrl = file.url;
		this.dialogVisible = true;
	}

	private handleAvatarSuccess(res: object, file: any) {
		this.imageUrl = URL.createObjectURL(file.raw);
	}

	private handlePreview(file: any) {
		console.log(file);
	}
	private handleExceed(files: any, fileList: any) {
		this.$message.warning(
			`当前限制选择 3 个文件，本次选择了 ${
				files.length
			} 个文件，共选择了 ${files.length + fileList.length} 个文件`
		);
	}
	private beforeRemove(file: any) {
		return this.$confirm(`确定移除 ${file.name}？`);
	}

	private onSubmit() {
		console.log(123);
	}
}
